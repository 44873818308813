import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['selectedFields', 'commitButton'];

  connect() {
    this.selectedFieldsPrevValue = this.getSelectedValues();
  }

  updateFields() {
    const selectedFields = this.getSelectedValues();
    this.toggleCommitButton(selectedFields.length > 0);

    const allFields = new Set([...this.selectedFieldsPrevValue, ...selectedFields]);
    allFields.forEach(field => {
      const isVisible = selectedFields.includes(field);
      this.toggleFieldVisibility(field, isVisible);
    });

    this.selectedFieldsPrevValue = selectedFields;
  }

  getSelectedValues() {
    return Array.from(this.selectedFieldsTarget.selectedOptions).map(option => option.value);
  }

  toggleCommitButton(enabled) {
    this.commitButtonTarget.toggleAttribute('disabled', !enabled);
  }

  toggleFieldVisibility(field, isVisible) {
    const input = document.getElementById(`batch_coupons_${field}`);
    if (!input) return;

    const formGroup = input.closest('.form-group');
    if (formGroup) {
      formGroup.classList.toggle('d-none', !isVisible);
    }
  }
}