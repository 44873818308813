import { AdService } from './src/ad_service';
import { Blogs } from './src/blogs';
import { CouponStats } from './src/coupon_stats';
import { StoreLocations } from './src/store_locations';
import { Stores } from './src/stores';
import { Signup } from './src/signup';

$(document).ready(function() {
  Blogs.setup();
  CouponStats.setup();
  StoreLocations.setup();
  Stores.setup();
  Signup.setup();
  AdService.setup();
});
